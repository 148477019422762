import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import "./App.css"

import { loadWeb3, checkAndSwitchNetwork } from "./components/web3Setup";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Bounce } from "react-toastify";

import Home from './pages/Home';
import Marketplace from './pages/Marketplace';
import Collections from './pages/Collections';
function App() {

  const [isConnecting, setIsConnecting] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [metamaskAddress, setMetamaskAddress] = useState(null)

  const onConnect = async () => {
    console.log('Attempting to connect...');
    setIsConnected(false);
    setIsConnecting(true);

    const web3Loaded = await loadWeb3();

    if (web3Loaded) {

      let isCorrectNetwrok = await checkAndSwitchNetwork();

      if (isCorrectNetwrok) {

        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0];

        setIsConnected(true);
        setIsConnecting(false);
        setMetamaskAddress(account);
        notify("Wallet is connected");
        sessionStorage.setItem('isConnected', true);

      } else {
        setIsConnected(false);
        setIsConnecting(false);
        notify("Please switch your wallet to SatoshiVM Alpha Mainnet.", "error");
      }
    } else {
      setIsConnected(false);
      setIsConnecting(false);
      notify("Error while connecting wallet.", "error");
    }
  };

  const onDisconnect = () => {
    setIsConnecting(false)
    setIsConnected(false)
    setMetamaskAddress(null)
    sessionStorage.clear()
  }

  const notify = (message, type = "success") => {
    console.log('asd:',message);
    toast[type](message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      sessionStorage.clear();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home
            isConnected={isConnected}
            isConnecting={isConnecting}
            metamaskAddress={metamaskAddress}
            onConnect={() => onConnect()}
            onDisconnect={() => onDisconnect()}
            notify={(message, type) => notify(message, type)}
          />} />
          <Route path="/collections" element={
            <Collections
              isConnected={isConnected}
              isConnecting={isConnecting}
              metamaskAddress={metamaskAddress}
              onConnect={() => onConnect()}
              onDisconnect={() => onDisconnect()}
              notify={(message, type) => notify(message, type)}
            />} />
          <Route path="/marketplace" element={
            <Marketplace
              isConnected={isConnected}
              isConnecting={isConnecting}
              metamaskAddress={metamaskAddress}
              onConnect={() => onConnect()}
              onDisconnect={() => onDisconnect()}
              notify={(message, type) => notify(message, type)}
            />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>

  );
}

export default App;
