import React, { useState, useEffect } from "react";

import Web3 from "web3";
import axios from 'axios';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { contractAddress, contractABI } from "../contracts/marketplaceContractInfo";

import "./marketplace.css"

import X from "../assets/X.png";
import TG from "../assets/TG.png";
import logo from "../assets/logo.png";
import hamburger from "../assets/hamburger.svg";
import poweredby from "../assets/poweredby.png";
import placeholder from "../assets/nft-placeholder.png";

const API = "https://svmscan.io/api/v2"
const apiOptions = {
    headers: {
        'Content-Type': 'application/json',
    }
};

const Marketplace = ({ isConnecting, isConnected, metamaskAddress, onConnect, onDisconnect, notify }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [whitelistedNFTs, setWhitelistedNFTs] = useState([]);

    const [activeNfts, setActiveNfts] = useState([]);
    const [isActiveNftsFetching, setIsActiveNftsFetching] = useState(true);
    const [firstListStatus, setFirstListStatus] = useState({});

    // Active Market Items
    const fetchActiveMarketItems = async () => {
        const rpcURL = 'https://alpha-rpc-node-http.svmscan.io';
        const web3 = new Web3(new Web3.providers.HttpProvider(rpcURL));
        const contract = new web3.eth.Contract(contractABI, contractAddress);
        setIsActiveNftsFetching(true);

        try {
            let list = await contract.methods.fetchActiveMarketItems().call();
            let activeNfts = list.filter(f => whitelistedNFTs.includes(f.nftContract.toLowerCase()));

            const seenAddresses = new Set();
            const uniqueNfts = activeNfts.filter(nft => {
                const isDuplicate = seenAddresses.has(nft.nftContract.toLowerCase());
                seenAddresses.add(nft.nftContract.toLowerCase());
                return !isDuplicate;
            });
            if (uniqueNfts?.length) {
                let addImgPromises = uniqueNfts.map(nft => axios.get(`${API}/tokens/${nft.nftContract}/instances/1`, apiOptions));
                let addImgResponses = await Promise.all(addImgPromises);
                let addImgData = addImgResponses.map(response => {
                    return {
                        image_url: response?.data?.image_url, address: response?.data?.token?.address
                    }
                });

                let nftsPromises = uniqueNfts.map(nft => axios.get(`${API}/tokens/${nft.nftContract.toLowerCase()}`, apiOptions));
                let nftsResponses = await Promise.all(nftsPromises);

                let uniqueNftsData = nftsResponses.map(response => response.data)


                let combinedNftsData = uniqueNftsData.map(nft => {
                    let imageEntry = addImgData.find(img => img.address.toLowerCase() === nft.address.toLowerCase());
                    return { ...nft, image_url: imageEntry?.image_url };
                });

                console.log('Active Nfts:', combinedNftsData);
                setIsActiveNftsFetching(false);
                setActiveNfts(combinedNftsData);
            } else {
                setIsActiveNftsFetching(false);
                setActiveNfts([]);
            }
        } catch (error) {
            setIsActiveNftsFetching(false);
            setActiveNfts([]);
            console.error("Error fetching data:", error);
        }
    };

    // Fetch Whitelisted Addresses
    useEffect(() => {
        fetch('/whitelist.json')
            .then(response => response.json())
            .then(data => {
                let refinedData = data.map(m => m.toLowerCase())
                setWhitelistedNFTs(refinedData);
            })
            .catch(error => {
                setWhitelistedNFTs([]);
                console.error("Failed to fetch whitelist:", error)
            }
            );
    }, []);

    // Fetch Active Market Items
    useEffect(() => {
        if (whitelistedNFTs.length > 0) {
            fetchActiveMarketItems();
        }
    }, [whitelistedNFTs]);

    const handleDisconnect = () => {
        onDisconnect()
        notify("Wallet Disconnect", "error");
        sessionStorage.setItem('isConnected', false);
    };

    const handleImageLoaded = (num, id) => {
        if (num === 'first')
            setFirstListStatus((prevStatus) => ({ ...prevStatus, [id]: 'loaded' }));
    };

    const handleImageError = (num, id) => {
        if (num === 'first')
            setFirstListStatus((prevStatus) => ({ ...prevStatus, [id]: 'error' }));
    };
    return (
        <>
            {isFullScreen && (
                <div className="fullscreen-menu">
                    <div className="flex justify-end">
                        <div onClick={() => setIsFullScreen(false)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                fill="none"
                                viewBox="0 0 48 48"
                            >
                                <path
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                    d="M10 10l14 14 14-14M10 38l14-14 14 14"
                                >

                                </path>
                            </svg>
                        </div>
                    </div>
                    <ul className="text-center flex flex-col gap-4">
                        <li onClick={() => setIsFullScreen(false)} className="text-white ">
                            {" "}
                            <a href="/#hero">Home</a>{" "}
                        </li>
                        <li onClick={() => setIsFullScreen(false)} className="text-white ">
                            {" "}
                            <a href="/#mint">Mint</a>{" "}
                        </li>
                        <li onClick={() => setIsFullScreen(false)} className="text-white">
                            {" "}
                            <a href="/#tokenomics">Tokenomics</a>{" "}
                        </li>
                        <li onClick={() => setIsFullScreen(false)} className="text-white">
                            {" "}
                            <a href="/#roadmap">Roadmap</a>{" "}
                        </li>
                        <li onClick={() => setIsFullScreen(false)} className="text-white">
                            <Link
                                to="/collections"
                                className="text-market font-bold cursor-pointer"
                            >
                                NFT MARKETPLACE
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
            <section className="bg-red py-8 relative font-primary">
                <div className="w-[100%] md:[90%] xl:w-[85%] mx-auto flex justify-between items-center">
                    <div className="steak-logo-wrapper">
                        <img
                            className="steak-logo w-[72px] h-[72px] md:w-[150px] md:h-[150px]"
                            src={logo}
                            alt=""
                        />
                        <p>A meme coin  with real <br /> utility!</p>
                    </div>
                    <div className="md:flex gap-6 hidden lg:ml-24 2xl:ml-0">
                        <a href="/#hero" className="text-white font-bold cursor-pointer">
                            HOME
                        </a>
                        <a href="/#mint" className="text-white font-bold cursor-pointer">
                            MINT
                        </a>
                        <a
                            href="/#tokenomics"
                            className="text-white font-bold cursor-pointer"
                        >
                            TOKENOMICS
                        </a>
                        <a
                            href="/#roadmap"
                            className="text-white font-bold cursor-pointer"
                        >
                            ROADMAP
                        </a>
                        <Link
                            to="/collections"
                            className="text-market font-bold cursor-pointer"
                        >
                            NFT MARKETPLACE
                        </Link>
                    </div>
                    <a
                        onClick={isConnected ? handleDisconnect : onConnect}
                        className="btn btn-white"
                    >
                        {isConnecting
                            ? "Connecting..."
                            : isConnected
                                ? "Disconnect"
                                : "Connect Wallet"}
                    </a>
                    <img
                        onClick={() => setIsFullScreen(true)}
                        src={hamburger}
                        className="block md:hidden cursor-pointer"
                        alt=""
                    />
                </div>
            </section>

            <div className="marketplace">
                <div className="bg-white font-primary">
                    <h2 className="text-3xl font-bold mb-8">NFT Collections:</h2>
                    {
                        isActiveNftsFetching
                            ?
                            <p>Fetching Data....</p>
                            :

                            activeNfts.length
                                ?
                                <div className="flex flex-wrap gap-8">
                                    {activeNfts.map((nft) => (
                                        <div className="nft-card  min-w-[250px]" key={nft.address}>
                                            <div className="nft-image-container">
                                                <ImageLoader
                                                    type="first"
                                                    imageStatus={firstListStatus}
                                                    src={nft.image_url}
                                                    handleImageLoaded={handleImageLoaded}
                                                    handleImageError={handleImageError}
                                                />
                                            </div>
                                            <div className="nft-info">
                                                <p className="nft-title">{nft?.name}</p>
                                                <p className="nft-subtitle">{nft?.symbol}</p>
                                            </div>
                                            <div className="flex nft-action">
                                                <button className="btn-buy" onClick={() => navigate(`/marketplace?address=${nft.address}`, { state: { name:nft?.name, supply: nft?.total_supply, holders: nft?.holders } })}>View Collection</button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <p className="text-center my-8">No NFTs to Show</p>
                    }
                </div>
            </div>
            <footer style={{ background: '#101112' }}>
                <div className="w-[100%] md:[90%] xl:w-[90%] mx-auto flex justify-between items-center">
                    <div className="flex items-center">
                        <img src={poweredby} alt="Your Logo" className="w-92 mr-2" />
                    </div>

                    <div className="flex items-center gap-16">
                        <a href="https://twitter.com/SteakMemeCoin" target="_blank" rel="noreferrer">
                            <img
                                src={X}
                                alt="Twitter Logo"
                                className="w-12 hover:scale-105 transition-all duration-200 ease-out"
                            />
                        </a>
                        <a href="https://t.me/SteakMemeCoin" target="_blank" rel="noreferrer">
                            <img
                                src={TG}
                                alt="Telegram Logo"
                                className="w-12 hover:scale-105 transition-all duration-200 ease-out"
                            />
                        </a>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Marketplace;

const ImageLoader = ({ type, src, id, handleImageLoaded, handleImageError, imageStatus }) => {
    return (
        <>
            {imageStatus[id] === 'error'
                &&
                <img className="placeholder" src={placeholder} />
            }
            {imageStatus[id] !== 'error' && imageStatus[id] !== 'loaded' &&
                <div className="skeleton" />
            }
            {
                <img
                    src={src}
                    onLoad={() => handleImageLoaded(type, id)}
                    onError={() => handleImageError(type, id)}
                    style={{ display: imageStatus[id] === 'loaded' ? 'block' : 'none' }}
                />
            }
        </>
    );
};
