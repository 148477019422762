import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import TokenomicsImage from "../assets/transperent.png";

const Tokenomics = () => {
    const dataMock = [
        { title: 'Mintable', value: 75, color: '#E38627' },
        { title: 'Burned LP', value: 10, color: '#C13C37' },
        { title: 'Development', value: 14, color: '#6A2135' },
        { title: 'Team', value: 1, color: '#FFD700' },
    ];

    return (
        <section className='tokenomics bg-white shadow-lg' id="tokenomics">
            <div className="flex flex-col lg:flex-row justify-center items-center">
                <div className="w-full lg:w-1/2">
                    {/* <PieChart
                        data={dataMock}
                        label={({ dataEntry }) => `${dataEntry.value}%`}
                        labelStyle={{
                            fontSize: '3px',
                            fill: '#ffffff',
                        }}
                        labelPosition={60}
                        radius={40}
                        animate
                        lineWidth={25}
                        startAngle={-90}
                        segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                        segmentsShift={(index) => (index === 0 ? 2 : 0)}
                        style={{
                            height: '500px',
                        }}
                    /> */}
                    <img src={TokenomicsImage} width="90%" />
                </div>
                <div className="w-full lg:w-1/2 mt-5 lg:mt-0 lg:ml-10 text-black">
                    <h3 className="text-3xl font-bold mb-4">TOKENOMICS:</h3>
                    <p className="mb-3">Max Supply: 21,000,000</p>
                    <ul className="list-disc pl-5">
                        <li className="mb-2">75% mintable by anyone. A small mint fee* prevents bots and ensures a fair and decentralized distribution.</li>
                        <li className="mb-2">14% DApps development on SatoshiVM, CEX, airdrops, marketing, bridge...</li>
                        <li className="mb-2">10% Burned LP</li>
                        <li className="mb-2">1% Team (for the single dev to dump at $200 mil marketcap and then get depressed when
                            $STEAK gets to 2 billion and surpasses $BONK)</li>
                    </ul>
                    <p className="text-sm mt-4">
                        <strong>CA:</strong> 0x12402b233686e72654dE9248927d9B8cddb24618
                    </p>
                    <p className="text-xs mt-4">
                        *Minting fees are the sole property of the developer, who has unrestricted discretion over their use. Some will be used for the initial LP that will be burned and other development expenses.
                    </p>
                </div>
            </div>
        </section>
    );
};
export default Tokenomics;
