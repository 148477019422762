import Web3 from 'web3';

export const loadWeb3 = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' }); // Modern way to request access
      console.log("Web3 injected browser detected.");
      return true;
    } catch (error) {
      console.error("User denied account access");
      return false;
    }
  } else {
    console.log("Non-Ethereum browser detected. You should consider trying MetaMask!");
    return false;
  }
};

export const checkAndSwitchNetwork = async () => {
  const chainIdHex = '0xc25';

  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainIdHex }],
    });
    return true;
  } catch (switchError) {
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [{
            chainId: chainIdHex,
            rpcUrls: ['https://alpha-rpc-node-http.svmscan.io'],
            chainName: "SatoshiVM Alpha Mainnet",
            nativeCurrency: {
              name: "BitCoin",
              symbol: "BTC",
              decimals: 18
            },
            blockExplorerUrls: ["https://svmscan.io"]
          }],
        });
        return true;
      } catch (addError) {
        return false;
        console.error('Failed to add the network:', addError);
      }
    } else {
      return false;
      console.error('Failed to switch the network:', switchError);
    }
  }
};

