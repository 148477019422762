import React, { useState, useEffect } from "react";

import Web3 from "web3";
import axios from 'axios';

import BigNumber from "bignumber.js";

import { contractAddress, contractABI } from "../contracts/marketplaceContractInfo";
import { ERC721ABI } from '../contracts/ERC721';
import { checkAndSwitchNetwork } from "../components/web3Setup";

import { Link, useLocation, useNavigate } from "react-router-dom";

import "./marketplace.css"

import logo from "../assets/logo.png";
import btc from "../assets/btc.svg";
import placeholder from "../assets/nft-placeholder.png";

import hamburger from "../assets/hamburger.svg";
import X from "../assets/X.png";
import TG from "../assets/TG.png";
import poweredby from "../assets/poweredby.png";
import back from "../assets/back.png";
const API = "https://svmscan.io/api/v2"
const apiOptions = {
    headers: {
        'Content-Type': 'application/json',
    }
};

const SortOptions = {
    PRICE_LOWEST: 'PRICE_LOWEST',
    PRICE_HIGHEST: 'PRICE_HIGHEST',
    DATE_NEWEST: 'DATE_NEWEST',
    DATE_OLDEST: 'DATE_OLDEST'
};

const Marketplace = ({ isConnecting, isConnected, metamaskAddress, onConnect, onDisconnect, notify }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const collectionAddress = queryParams.get('address')?.toLowerCase();
    const { name, supply, holders } = location.state || {};
    const [floorPrice, setFloorPrice] = useState(null);

    useEffect(() => {
        if (!collectionAddress) {
            navigate('/collections');
        }
    }, [collectionAddress, navigate]);

    const [activeTab, setActiveTab] = useState('first');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [whitelistedNFTs, setWhitelistedNFTs] = useState([]);

    const [isWaiting, setWaiting] = useState(null);
    const [contractInstance, setContractInstance] = useState(null);

    const [activeNfts, setActiveNfts] = useState([]);
    const [isActiveNftsFetching, setIsActiveNftsFetching] = useState(true);

    const [myNfts, setMyNfts] = useState([]);
    const [isMyNftsFetching, setIsMyNftsFetching] = useState(true);

    const [activeNftsByUser, setActiveNftsByUser] = useState([]);
    const [isActiveNftsFetchingByUser, setIsActiveNftsFetchingByUser] = useState(true);

    const [firstListStatus, setFirstListStatus] = useState({});
    const [secondListStatus, setSecondListStatus] = useState({});
    const [thirdListStatus, setThirdListStatus] = useState({});

    const [sortedNfts, setSortedNfts] = useState([...activeNfts]);
    const [sortOrder, setSortOrder] = useState(SortOptions.PRICE_LOWEST);

    useEffect(() => {
        setSortedNfts(sortNfts([...activeNfts], sortOrder));
    }, [activeNfts, sortOrder]);

    const sortNfts = (nftsToSort, order) => {
        switch (order) {
            case SortOptions.PRICE_LOWEST:
                return nftsToSort.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
            case SortOptions.PRICE_HIGHEST:
                return nftsToSort.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
            case SortOptions.DATE_NEWEST:
                return nftsToSort.reverse() // Assuming itemId is incremental and can represent the date
            case SortOptions.DATE_OLDEST:
                return nftsToSort;
            default:
                return nftsToSort;
        }
    };

    // Fetch Whitelisted Addresses
    useEffect(() => {
        fetch('/whitelist.json')
            .then(response => response.json())
            .then(data => {
                let refinedData = data.map(m => m.toLowerCase())
                setWhitelistedNFTs(refinedData);
            })
            .catch(error => {
                setWhitelistedNFTs([]);
                console.error("Failed to fetch whitelist:", error)
            }
            );
    }, []);

    // Fetch Active Market Items
    useEffect(() => {
        if (whitelistedNFTs.length > 0) {
            fetchActiveMarketItems();
        }
    }, [whitelistedNFTs]);

    useEffect(() => {
        console.log('isConnected:', isConnected);
        console.log('whitelistedNFTs:', whitelistedNFTs);
        console.log('metamaskAddress:', metamaskAddress);
        if (isConnected && metamaskAddress && whitelistedNFTs.length > 0) {
            console.log('---------IN---------');
            const web3 = new Web3(window.ethereum);
            const tempContract = new web3.eth.Contract(contractABI, contractAddress);
            setContractInstance(tempContract);

            fetchMyNfts();
            fetchActiveMarketItemsByUser();
        }
    }, [isConnected, whitelistedNFTs]);

    // Active Market Items
    const fetchActiveMarketItems = async () => {
        const rpcURL = 'https://alpha-rpc-node-http.svmscan.io';
        const web3 = new Web3(new Web3.providers.HttpProvider(rpcURL));
        const contract = new web3.eth.Contract(contractABI, contractAddress);
        setIsActiveNftsFetching(true);

        try {
            let list = await contract.methods.fetchActiveMarketItems().call();
            let activeNfts = list.filter(f => whitelistedNFTs.includes(f.nftContract.toLowerCase()) && f.nftContract.toLowerCase() == collectionAddress)

            if (activeNfts?.length) {
                let nftsPromises = activeNfts.map(nft => axios.get(`${API}/tokens/${nft.nftContract}/instances/${nft.tokenId}`, apiOptions));
                let nftsResponses = await Promise.all(nftsPromises);

                let nftsResponsesWithPrice = nftsResponses.map((response, index) => {
                    return {
                        ...response.data,
                        itemId: activeNfts[index].itemId,
                        price: weiToEthersBN(activeNfts[index].price),
                        isMine: activeNfts[index].owner?.hash?.toLowerCase() == metamaskAddress?.toLowerCase() ? true : false
                    };
                });
                const floorPriceValue = nftsResponsesWithPrice.reduce((minPrice, nft) => {
                    const currentPrice = Number(nft.price);
                    return minPrice === null || currentPrice < minPrice ? currentPrice : minPrice;
                }, null);

                setFloorPrice(floorPriceValue);

                setIsActiveNftsFetching(false)
                console.log('Active Nfts:', nftsResponsesWithPrice);
                setActiveNfts(nftsResponsesWithPrice);
            } else {
                setIsActiveNftsFetching(false)
                setActiveNfts([]);
            }
        } catch (error) {
            setIsActiveNftsFetching(false)
            setActiveNfts([]);
            console.error("Error fetching data:", error);
        }
    };

    // Active Market Items By User
    const fetchActiveMarketItemsByUser = async () => {
        const rpcURL = 'https://alpha-rpc-node-http.svmscan.io';
        const web3 = new Web3(new Web3.providers.HttpProvider(rpcURL));
        const contract = new web3.eth.Contract(contractABI, contractAddress);
        setIsActiveNftsFetchingByUser(true)
        try {
            let list = await contract.methods.fetchActiveMarketItemsByUser(metamaskAddress).call();
            let activeNftsByUser = list.filter(f => whitelistedNFTs.includes(f.nftContract.toLowerCase()) && f.nftContract.toLowerCase() == collectionAddress)
            if (activeNftsByUser?.length) {
                let nftsPromises = activeNftsByUser.map(nft => axios.get(`${API}/tokens/${nft.nftContract}/instances/${nft.tokenId}`, apiOptions));
                let nftsResponses = await Promise.all(nftsPromises);

                let nftsResponsesWithPrice = nftsResponses.map((response, index) => {
                    return {
                        ...response.data,
                        itemId: activeNftsByUser[index].itemId,
                        price: weiToEthersBN(activeNftsByUser[index].price)
                    };
                });
                console.log('Active Nfts By User:', nftsResponsesWithPrice);
                setIsActiveNftsFetchingByUser(false)
                setActiveNftsByUser(nftsResponsesWithPrice);
            } else {
                setIsActiveNftsFetchingByUser(false)
                setActiveNftsByUser([]);
            }
        } catch (error) {
            setIsActiveNftsFetchingByUser(false)
            setActiveNftsByUser([]);
            console.error("Error fetching data:", error);
        }
    };

    // Function to fetch all instances for a single token with pagination handling
    async function fetchAllInstancesForToken(tokenAddress, nextPageParams = '') {
        let allInstances = [];
        do {
            const url = `${API}/tokens/${tokenAddress}/instances${nextPageParams}`;
            const response = await axios.get(url, apiOptions);
            allInstances.push(...response.data.items);
            nextPageParams = response.data.next_page_params ? `?unique_token=${response.data.next_page_params.unique_token}` : '';
        } while (nextPageParams);
        return allInstances;
    }

    // Main function to fetch and filter owned NFTs based on whitelist
    async function fetchAndFilterOwnedNFTs(tokens) {
        let myNfts = [];
        // Fetch instances for each token with pagination
        let nftsPromises = tokens.map(nft =>
            fetchAllInstancesForToken(nft.token.address)
        );
        let nftsResponses = await Promise.all(nftsPromises);
        // let metamaskAddress = '0x26f69dC181b46d38163f2C70B2A3DA2852a11F71';

        // Filter instances based on ownership and whitelist, then accumulate
        nftsResponses.forEach(instances => {
            let userData = instances.filter(instance =>
                instance.owner?.hash.toLowerCase() === metamaskAddress.toLowerCase()
            );
            myNfts.push(...userData);
        });
        return myNfts;
    }

    // // My NFT's
    const fetchMyNfts = async () => {
        setIsMyNftsFetching(true);
        try {
            const response = await axios.get(`${API}/addresses/${metamaskAddress}/tokens?type=ERC-721`, apiOptions);
            if (response.data?.items.length) {
                let data = response.data.items.filter(f => whitelistedNFTs.includes(f.token.address.toLowerCase()) && f.token.address.toLowerCase() == collectionAddress)
                let myNfts = await fetchAndFilterOwnedNFTs(data);

                if (myNfts.length > 0) {
                    const web3 = new Web3(window.ethereum);
                    const approvalPromises = myNfts.map(ownedNft => {
                        const nftContract = new web3.eth.Contract(ERC721ABI, ownedNft.token.address);
                        return nftContract.methods.getApproved(ownedNft.id).call().then(approvedAddress => {
                            return {
                                ...ownedNft,
                                isApprove: approvedAddress.toLowerCase() === contractAddress.toLowerCase(),
                                price: 0
                            };
                        });
                    });

                    myNfts = await Promise.all(approvalPromises);
                }
                console.log('My Nfts:', myNfts);
                setMyNfts(myNfts);

            }
        } catch (error) {
            console.error("Error fetching owner items:", error);
            setMyNfts([]); // Assuming you want to clear the list in case of an error
        } finally {
            setIsMyNftsFetching(false);
        }
    };

    // sale item
    const onApprove = async (nft) => {
        setWaiting(true);
        const web3 = new Web3(window.ethereum);
        const nftContract = new web3.eth.Contract(ERC721ABI, nft?.token?.address);

        let isCorrectNetwrok = await checkAndSwitchNetwork();

        if (!isCorrectNetwrok) {
            notify("Please switch your wallet to SatoshiVM Alpha Mainnet.", "error");
            setWaiting(false);
            return
        }

        if (nftContract) {
            try {
                nftContract.methods
                    .approve(contractAddress, nft.id)
                    .send({
                        from: metamaskAddress,
                        value: 0,
                        maxFeePerGas: 75000000,
                        maxPriorityFeePerGas: 0,
                    })
                    .on("transactionHash", (transactionHash) => {
                        notify("Transaction submitted to network!", "success");
                        console.log(`Transaction hash: ${transactionHash}`);
                    })
                    .on("receipt", (receipt) => {
                        setTimeout(() => {
                            setWaiting(false);
                            fetchMyNfts()
                            fetchActiveMarketItems();
                            fetchActiveMarketItemsByUser();
                            notify("Approve transaction Completed!", "success");
                            console.log(`canceling tokens...`, receipt);
                        }, 3000);
                    })
                    .on("error", (error) => {
                        setWaiting(false);
                        notify("Error in Approve transaction", "error");
                        console.error("Error Approve transaction:", error);
                    })
                    .catch((error) => {
                        setWaiting(false);
                        if (error.code === 4001) {
                            notify("User rejected the transaction", "error");
                        } else {
                            notify("Error in Approve transaction", "error");
                        }
                    });
            } catch (error) {
                setWaiting(false);
            }
        } else {
            setWaiting(false);
        }
    };

    const onCreateMarketItem = async (nft) => {
        setWaiting(true);
        let isCorrectNetwrok = await checkAndSwitchNetwork();

        if (!isCorrectNetwrok) {
            setWaiting(false);
            notify("Please switch your wallet to SatoshiVM Alpha Mainnet.", "error");
            return
        }

        if (contractInstance) {
            try {
                contractInstance.methods
                    .createMarketItem(nft.token.address, nft.id, ethersToWeiBN(nft.price))
                    .send({
                        from: metamaskAddress,
                        value: 0,
                        maxFeePerGas: 75000000,
                        maxPriorityFeePerGas: 0,
                    })
                    .on("transactionHash", (transactionHash) => {
                        notify("Transaction submitted to network!", "success");
                        console.log(`Transaction hash: ${transactionHash}`);
                    })
                    .on("receipt", (receipt) => {
                        setTimeout(() => {
                            setWaiting(false);
                            fetchMyNfts()
                            fetchActiveMarketItems();
                            fetchActiveMarketItemsByUser();
                            notify("Sell transaction Completed!", "success");
                            console.log(`canceling tokens...`, receipt);
                        }, 3000);
                    })
                    .on("error", (error) => {
                        setWaiting(false);
                        notify("Error in sell transaction", "error");
                        console.error("Error in sell transaction:", error);
                    })
                    .catch((error) => {
                        setWaiting(false);
                        if (error.code === 4001) {
                            notify("User rejected the transaction", "error");
                        } else {
                            notify("Error in sell transaction", "error");
                        }
                    });
            } catch (error) {
                setWaiting(false);
            }
        } else {
            setWaiting(false);
        }
    };

    // cancel item
    const onCancelMarketItem = async (nft) => {
        setWaiting(true);
        let isCorrectNetwrok = await checkAndSwitchNetwork();

        if (!isCorrectNetwrok) {
            setWaiting(false);
            notify("Please switch your wallet to SatoshiVM Alpha Mainnet.", "error");
            return
        }

        if (contractInstance) {
            try {
                contractInstance.methods
                    .cancelMarketItem(nft.itemId)
                    .send({
                        from: metamaskAddress,
                        value: 0,
                        maxFeePerGas: 75000000,
                        maxPriorityFeePerGas: 0,
                    })
                    .on("transactionHash", (transactionHash) => {
                        notify("Transaction submitted to network!", "success");
                        console.log(`Transaction hash: ${transactionHash}`);
                    })
                    .on("receipt", (receipt) => {
                        setTimeout(() => {
                            setWaiting(false);
                            fetchMyNfts()
                            fetchActiveMarketItems();
                            fetchActiveMarketItemsByUser();
                            notify("NFT listing removed!", "success");
                            console.log(`canceling tokens...`, receipt);
                        }, 3000);
                    })
                    .on("error", (error) => {
                        setWaiting(false);
                        notify("Error in cancel transaction", "error");
                        console.error("Error in cancel transaction:", error);
                    })
                    .catch((error) => {
                        setWaiting(false);
                        if (error.code === 4001) {
                            notify("User rejected the transaction", "error");
                        } else {
                            notify("Error in cancel transaction", "error");
                        }
                    });
            } catch (error) {
                setWaiting(false);
            }
        } else {
            setWaiting(false);
        }
    };

    // price update
    const onUpdateMarketItemPrice = async (nft) => {
        setWaiting(true);
        let isCorrectNetwrok = await checkAndSwitchNetwork();

        if (!isCorrectNetwrok) {
            setWaiting(false);
            notify("Please switch your wallet to SatoshiVM Alpha Mainnet.", "error");
            return
        }

        if (contractInstance) {
            try {
                contractInstance.methods
                    .updateMarketItemPrice(nft.itemId, ethersToWeiBN(nft.price))
                    .send({
                        from: metamaskAddress,
                        value: 0,
                        maxFeePerGas: 75000000,
                        maxPriorityFeePerGas: 0,
                    })
                    .on("transactionHash", (transactionHash) => {
                        notify("Transaction submitted to network!", "success");
                        console.log(`Transaction hash: ${transactionHash}`);
                    })
                    .on("receipt", (receipt) => {
                        setTimeout(() => {
                            setWaiting(false);
                            fetchMyNfts()
                            fetchActiveMarketItems();
                            fetchActiveMarketItemsByUser();
                            notify("Price updated Successfully!", "success");
                            console.log(`canceling tokens...`, receipt);
                        }, 3000);
                    })
                    .on("error", (error) => {
                        setWaiting(false);
                        notify("Error in updating price", "error");
                        console.error("ErrorError in updating price:", error);
                    })
                    .catch((error) => {
                        setWaiting(false);
                        if (error.code === 4001) {
                            notify("User rejected the transaction", "error");
                        } else {
                            notify("Error in updating price", "error");
                        }
                    });
            } catch (error) {
                setWaiting(false);
            }
        } else {
            setWaiting(false);
        }
    };

    // sale item
    const onCreateMarketSale = async (nft) => {
        if (!isConnected) {
            notify("Please Connect you wallet.", "error");
            return
        }
        setWaiting(true);
        let isCorrectNetwrok = await checkAndSwitchNetwork();

        if (!isCorrectNetwrok) {
            setWaiting(false);
            notify("Please switch your wallet to SatoshiVM Alpha Mainnet.", "error");
            return
        }

        if (contractInstance) {
            try {
                contractInstance.methods
                    .createMarketSale(nft.itemId)
                    .send({
                        from: metamaskAddress,
                        value: ethersToWeiBN(nft.price),
                        maxFeePerGas: 75000000,
                        maxPriorityFeePerGas: 0,
                    })
                    .on("transactionHash", (transactionHash) => {
                        notify("Transaction submitted to network!", "success");
                        console.log(`Transaction hash: ${transactionHash}`);
                    })
                    .on("receipt", (receipt) => {
                        setTimeout(() => {
                            setWaiting(false);
                            fetchMyNfts()
                            fetchActiveMarketItems();
                            fetchActiveMarketItemsByUser();
                            notify("Buy transaction Completed!", "success");
                            console.log(`canceling tokens...`, receipt);
                        }, 3000);
                    })
                    .on("error", (error) => {
                        setWaiting(false);
                        notify("Error in buying", "error");
                        console.error("Error in buying:", error);
                    })
                    .catch((error) => {
                        setWaiting(false);
                        if (error.code === 4001) {
                            notify("User rejected the transaction", "error");
                        } else {
                            notify("Error in buying", "error");
                        }
                    });
            } catch (error) {
                setWaiting(false);
            }
        } else {
            setWaiting(false);
        }
    };

    // price Input
    const handlePriceUpdate = (id, newPrice) => {
        const updatedNfts = activeNftsByUser.map(nft => {
            if (nft.id === id) {
                return { ...nft, price: newPrice };
            }
            return nft;
        });

        setActiveNftsByUser(updatedNfts);
    };

    // Price input
    const handlePriceChange = (id, newPrice) => {
        const updatedNfts = myNfts.map(nft => {
            if (nft.id === id) {
                return { ...nft, price: newPrice };
            }
            return nft;
        });

        setMyNfts(updatedNfts);
    };

    const handleDisconnect = () => {
        onDisconnect()
        setContractInstance(null);
        notify("Wallet Disconnect", "error");
        sessionStorage.setItem('isConnected', false);
    };

    function weiToEthersBN(weiValue) {
        const weiValueStr = weiValue.toString();

        return new BigNumber(weiValueStr)
            .dividedBy(new BigNumber("10").pow(18))
            .toString();
    }

    function ethersToWeiBN(etherValue) {
        const etherValueStr = etherValue.toString();

        return new BigNumber(etherValueStr)
            .multipliedBy(new BigNumber("10").pow(18))
            .toString();
    }

    const handleImageLoaded = (num, id) => {
        if (num === 'first')
            setFirstListStatus((prevStatus) => ({ ...prevStatus, [id]: 'loaded' }));
        else if (num === 'second')
            setSecondListStatus((prevStatus) => ({ ...prevStatus, [id]: 'loaded' }));
        else if (num === 'third')
            setThirdListStatus((prevStatus) => ({ ...prevStatus, [id]: 'loaded' }));
    };

    const handleImageError = (num, id) => {
        if (num === 'first')
            setFirstListStatus((prevStatus) => ({ ...prevStatus, [id]: 'error' }));
        else if (num === 'second')
            setSecondListStatus((prevStatus) => ({ ...prevStatus, [id]: 'error' }));
        else if (num === 'third')
            setThirdListStatus((prevStatus) => ({ ...prevStatus, [id]: 'error' }));
    };

    return (
        <>
            {isFullScreen && (
                <div className="fullscreen-menu">
                    <div className="flex justify-end">
                        <div onClick={() => setIsFullScreen(false)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                fill="none"
                                viewBox="0 0 48 48"
                            >
                                <path
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                    d="M10 10l14 14 14-14M10 38l14-14 14 14"
                                >

                                </path>
                            </svg>
                        </div>
                    </div>
                    <ul className="text-center flex flex-col gap-4">
                        <li onClick={() => setIsFullScreen(false)} className="text-white ">
                            {" "}
                            <a href="/#hero">Home</a>{" "}
                        </li>
                        <li onClick={() => setIsFullScreen(false)} className="text-white ">
                            {" "}
                            <a href="/#mint">Mint</a>{" "}
                        </li>
                        <li onClick={() => setIsFullScreen(false)} className="text-white">
                            {" "}
                            <a href="/#tokenomics">Tokenomics</a>{" "}
                        </li>
                        <li onClick={() => setIsFullScreen(false)} className="text-white">
                            {" "}
                            <a href="/#roadmap">Roadmap</a>{" "}
                        </li>
                        <li onClick={() => setIsFullScreen(false)} className="text-white">
                            <Link
                                to="/collections"
                                className="text-market font-bold cursor-pointer"
                            >
                                NFT MARKETPLACE
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
            <section className="bg-red py-8 relative font-primary">
                <div className="w-[100%] md:[90%] xl:w-[85%] mx-auto flex justify-between items-center">
                    <div className="steak-logo-wrapper">
                        <img
                            className="steak-logo w-[72px] h-[72px] md:w-[150px] md:h-[150px]"
                            src={logo}
                            alt=""
                        />
                        <p>A meme coin  with real <br /> utility!</p>
                    </div>
                    <div className="md:flex gap-6 hidden lg:ml-24 2xl:ml-0">
                        <a href="/#hero" className="text-white font-bold cursor-pointer">
                            HOME
                        </a>
                        <a href="/#mint" className="text-white font-bold cursor-pointer">
                            MINT
                        </a>
                        <a
                            href="/#tokenomics"
                            className="text-white font-bold cursor-pointer"
                        >
                            TOKENOMICS
                        </a>
                        <a
                            href="/#roadmap"
                            className="text-white font-bold cursor-pointer"
                        >
                            ROADMAP
                        </a>
                        <Link
                            to="/collections"
                            className="text-market font-bold cursor-pointer"
                        >
                            NFT MARKETPLACE
                        </Link>
                    </div>
                    <a
                        onClick={isConnected ? handleDisconnect : onConnect}
                        className="btn btn-white"
                    >
                        {isConnecting
                            ? "Connecting..."
                            : isConnected
                                ? "Disconnect"
                                : "Connect Wallet"}
                    </a>
                    <img
                        onClick={() => setIsFullScreen(true)}
                        src={hamburger}
                        className="block md:hidden cursor-pointer"
                        alt=""
                    />
                </div>
            </section>

            <div className="marketplace">
                <div className="bg-white font-primary">
                    <div>
                        <Link to="/collections">
                            <img src={back} alt="" className="mb-4" width="120px" />
                        </Link>
                        <div className="flex justify-between items-center">
                            <h2 className="text-4xl font-bold mb-">{name}</h2>
                        </div>
                        <div className="stats">
                            <div className="stat  sep-line">
                                <div className="text-lg font-semibold text-gray-700" style={{ textWrap: "nowrap" }}>
                                    <p className="flex"><img className="mr-1" src={btc} alt="" />{parseFloat(Number(floorPrice).toFixed(8)).toString()} BTC</p>
                                </div>
                                <div className="text-md text-gray-500">Floor price</div>
                            </div>
                            <div className="stat sep-line">
                                <div className="text-lg font-semibold text-gray-700">{supply}</div>
                                <div className="text-md text-gray-500">Supply</div>
                            </div>
                            <div className="stat sep-line">
                                <div className="text-lg font-semibold text-gray-700">{holders}</div>
                                <div className="text-md text-gray-500">Holders</div>
                            </div>
                            <div className="stat">
                                <div className="text-lg font-semibold text-gray-700">{activeNfts.length}</div>
                                <div className="text-md text-gray-500">Listed</div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <ul class="content-nav">
                            <li>
                                <a class={`btnn ${activeTab == 'first' ? 'active' : ''} `} onClick={() => setActiveTab('first')}>
                                    <span>Listed <br /> NFTs</span>
                                </a>
                            </li>
                            <div class="divider-two"></div>
                            <li>
                                <a class={`btnn ${activeTab == 'second' ? 'active' : ''} `} onClick={() => setActiveTab('second')}>
                                    <span>List <br /> Your<br /> NFT</span>
                                </a>
                            </li>
                            <div class="divider-two"></div>
                            <li>
                                <a class={`btnn ${activeTab == 'third' ? 'active' : ''} `} onClick={() => setActiveTab('third')}>
                                    <span>Your<br /> Listed<br /> NFTs</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    {
                        activeTab == 'first' &&
                        <>
                            <h2 className="text-3xl font-bold mt-8 mb-8">Listed NFTs:</h2>
                            {
                                isActiveNftsFetching
                                    ?
                                    <p>Fetching Data....</p>
                                    :

                                    activeNfts.length
                                        ?
                                        <>
                                            {
                                                activeTab == 'first' &&
                                                <div className="flex mb-8">
                                                    <select value={sortOrder} onChange={e => setSortOrder(e.target.value)}>
                                                        <option value={SortOptions.PRICE_LOWEST}>Price: Low to High</option>
                                                        <option value={SortOptions.PRICE_HIGHEST}>Price: High to Low</option>
                                                        <option value={SortOptions.DATE_NEWEST}>Date: Newest</option>
                                                        <option value={SortOptions.DATE_OLDEST}>Date: Oldest</option>
                                                    </select>
                                                </div>
                                            }
                                            <div className="flex flex-wrap gap-8">
                                                {sortedNfts.map((nft) => (
                                                    <div className="nft-card  min-w-[250px]" key={nft.id}>
                                                        <div className="nft-image-container">
                                                            <ImageLoader
                                                                type="first"
                                                                imageStatus={firstListStatus}
                                                                src={nft.image_url}
                                                                handleImageLoaded={handleImageLoaded}
                                                                handleImageError={handleImageError}
                                                            />
                                                        </div>
                                                        <div className="nft-info">
                                                            <p className="nft-title">{nft?.metadata?.name}</p>
                                                            <p className="nft-subtitle">{nft?.token?.name}</p>
                                                            <div className="nft-buy-section">
                                                                <p className="nft-label">
                                                                    <img src={btc} alt="" />
                                                                    <b>
                                                                        {parseFloat(Number(nft.price).toFixed(8)).toString()} BTC
                                                                    </b>
                                                                </p>
                                                                <p className="nft-id">#{nft.id}</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex nft-action">
                                                            {
                                                                activeNftsByUser.find(f => f.itemId?.toString() == nft.itemId?.toString()) ?
                                                                    <button className="btn-buy" disabled={true}>Your NFT</button>
                                                                    :
                                                                    <button className="btn-buy" onClick={() => onCreateMarketSale(nft)} disabled={isWaiting}>Buy Now</button>
                                                            }
                                                            <a className="btn-buy" href={`https://svmscan.io/token/${nft.token.address}/instance/${nft.id}`} target="_blank">View on explorer</a>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                        :
                                        <p className="text-center my-8">No NFTs to Show</p>
                            }
                        </>

                    }
                    {
                        activeTab == 'second' &&
                        <>
                            <h2 className="text-3xl font-bold mt-8 mb-2">List your NFT:</h2>
                            <p className="mb-8">1% marketplace fee is taken from the seller. Market profits will go to $STEAK stakers.</p>
                            {
                                isConnected ?
                                    isMyNftsFetching
                                        ?
                                        <p>Fetching Data....</p>
                                        :

                                        myNfts.length
                                            ?
                                            <div className="flex flex-wrap gap-8">
                                                {myNfts.map((nft) => (
                                                    <div className="nft-card  min-w-[250px]" key={nft.id}>
                                                        <div className="nft-image-container">
                                                            <ImageLoader
                                                                type="second"
                                                                imageStatus={secondListStatus}
                                                                src={nft.image_url}
                                                                handleImageLoaded={handleImageLoaded}
                                                                handleImageError={handleImageError}
                                                            />
                                                        </div>
                                                        <div className="nft-info">
                                                            <p className="nft-title">{nft?.metadata?.name}</p>
                                                            <p className="nft-subtitle">{nft?.token?.name}</p>
                                                            {
                                                                nft.isApprove &&
                                                                <p className="nft-label">Price:
                                                                </p>
                                                            }
                                                            <div className="nft-buy-section">
                                                                {
                                                                    nft.isApprove ?
                                                                        <div className="flex">
                                                                            <input type="number"
                                                                                placeholder="Enter price"
                                                                                className="price-input"
                                                                                value={nft.price}
                                                                                onChange={(e) => handlePriceChange(nft.id, e.target.value)}
                                                                            />
                                                                            <img src={btc} alt="" />
                                                                        </div>
                                                                        : <div></div>
                                                                }
                                                                <button className="nft-id">#{nft.id}</button>
                                                            </div>
                                                        </div>
                                                        <div className="flex nft-action">
                                                            {
                                                                nft.isApprove
                                                                    ?
                                                                    <button className="btn-buy" onClick={() => onCreateMarketItem(nft)} disabled={isWaiting}>List NFT</button>
                                                                    :
                                                                    <button className="btn-buy" onClick={() => onApprove(nft)} disabled={isWaiting}>Approve</button>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <p className="text-center my-8">No NFTs to Show</p>
                                    :
                                    <a
                                        onClick={isConnected ? handleDisconnect : onConnect}
                                        className="btn  btn-con"
                                    >
                                        {isConnecting
                                            ? "Connecting..."
                                            : isConnected
                                                ? "Disconnect"
                                                : "Connect Wallet"}
                                    </a>
                            }

                        </>
                    }

                    {
                        activeTab == 'third' &&
                        <>
                            <h2 className="text-3xl font-bold mt-8 mb-2">Your listed NFTs:</h2>
                            <p className="mb-8">1% marketplace fee is taken from the seller. Market profits will go to $STEAK stakers.</p>
                            {
                                isConnected ?
                                    isActiveNftsFetchingByUser
                                        ?
                                        <p>Fetching Data....</p>
                                        :

                                        activeNftsByUser.length
                                            ?
                                            <div className="flex flex-wrap gap-8">
                                                {activeNftsByUser.map((nft) => (
                                                    <div className="nft-card  min-w-[250px]" key={nft.id}>
                                                        <div className="nft-image-container">
                                                            <ImageLoader
                                                                type="third"
                                                                imageStatus={thirdListStatus}
                                                                src={nft.image_url}
                                                                handleImageLoaded={handleImageLoaded}
                                                                handleImageError={handleImageError}
                                                            />
                                                        </div>
                                                        <div className="nft-info">
                                                            <p className="nft-title">{nft?.metadata?.name}</p>
                                                            <p className="nft-subtitle">{nft?.token?.name}</p>
                                                            <p className="nft-label">Change Price:
                                                            </p>
                                                            <div className="nft-buy-section">
                                                                <div className="flex">
                                                                    <input type="number"
                                                                        placeholder="New price"
                                                                        className="price-input"
                                                                        value={nft.price}
                                                                        onChange={(e) => handlePriceUpdate(nft.id, e.target.value)}
                                                                    />
                                                                    <img src={btc} alt="" />
                                                                </div>
                                                                <button className="nft-id">#{nft.id}</button>
                                                            </div>
                                                        </div>
                                                        <div className="flex nft-action">
                                                            <button className="btn-buy" onClick={() => onUpdateMarketItemPrice(nft)} disabled={isWaiting}>Change Price</button>
                                                            <button className="btn-buy remove" onClick={() => onCancelMarketItem(nft)} disabled={isWaiting}>Remove Listing</button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <p className="text-center my-8">No NFTs to Show</p>
                                    :
                                    <a
                                        onClick={isConnected ? handleDisconnect : onConnect}
                                        className="btn  btn-con"
                                    >
                                        {isConnecting
                                            ? "Connecting..."
                                            : isConnected
                                                ? "Disconnect"
                                                : "Connect Wallet"}
                                    </a>
                            }
                        </>
                    }


                </div>
            </div>
            <footer style={{ background: '#101112' }}>
                <div className="w-[100%] md:[90%] xl:w-[90%] mx-auto flex justify-between items-center">
                    {/* Left side: Logo and Name */}
                    <div className="flex items-center">
                        <img src={poweredby} alt="Your Logo" className="w-92 mr-2" />
                    </div>

                    {/* Right side: Social Icons */}
                    <div className="flex items-center gap-16">
                        <a href="https://twitter.com/SteakMemeCoin" target="_blank" rel="noreferrer">
                            <img
                                src={X}
                                alt="Twitter Logo"
                                className="w-12 hover:scale-105 transition-all duration-200 ease-out"
                            />
                        </a>
                        <a href="https://t.me/SteakMemeCoin" target="_blank" rel="noreferrer">
                            <img
                                src={TG}
                                alt="Telegram Logo"
                                className="w-12 hover:scale-105 transition-all duration-200 ease-out"
                            />
                        </a>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Marketplace;

const ImageLoader = ({ type, src, id, handleImageLoaded, handleImageError, imageStatus }) => {
    return (
        <>
            {imageStatus[id] === 'error'
                &&
                <img className="placeholder" src={placeholder} />
            }
            {imageStatus[id] !== 'error' && imageStatus[id] !== 'loaded' &&
                <div className="skeleton" />
            }
            {
                <img
                    src={src}
                    onLoad={() => handleImageLoaded(type, id)}
                    onError={() => handleImageError(type, id)}
                    style={{ display: imageStatus[id] === 'loaded' ? 'block' : 'none' }}
                />
            }
        </>
    );
};
